/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const pkg = require('./package.json');

exports.onInitialClientRender = () => {
  console.log(`[Site] Version ${pkg.version}`);
};
